<template>
  <div class="bg-yellow-200 font-cat">
    <div class="container mx-auto py-4 p-4 md:py-24">
      <div class="bg-yellow-300 p-4 md:p-8 rounded-3xl">
        <div class="grid grid-cols-1 md:grid-cols-2">
          <div class="prose-sm md:prose p-4 md:p-6 md:py-12 md:pr-0">
            <h1 class="font-semibold flex-grow">
              Compare Cat Food Subscriptions...
            </h1>

            <p>
              One of the recent developments in the cat food industry is the
              ability to get your cat the perfect food with a tailored cat food
              subscription service. This has many multiple benefits including
              access to healthy food and a regular monthly delivery, so you
              never run out (we’ve all been there!).
            </p>
            <p>
              Most of these monthly cat food packages offer healthier food than
              typically found in supermarkets, but not all. To help you separate
              the super subscriptions from the terrible trial boxes, here’s a
              handy guide to help you compare all the key aspects that each cat
              food brand has to offer. This will allow you to buy the best cat
              food subscription for you and your feline friend!
            </p>
          </div>
          <div class="flex items-end justify-center">
            <div class="grid grid-cols-2">
              <div class="flex items-end justify-center">
                <img
                  class="w-full -mt-14 md:-mt-24"
                  src="/images/cat-peek.png"
                />
              </div>
              <div class="flex items-end justify-center">
                <img
                  class="w-full -mt-14 md:-mt-24"
                  src="/images/cat-peek-2.png"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          class="-ml-8 -mr-8 sm:-ml-20 sm:-mr-20 bg-white py-8 md:p-8 rounded-2xl shadow-3xl"
        >
          <div
            class="flex w-full justify-center text-sm mb-6 -mt-2 md:hidden text-gray-500 text-center"
          >
            Scroll across for more &rarr;
          </div>
          <div
            class="flex flex-row w-full divide-x overflow-x-scroll flex-nowrap"
          >
            <div
              class="divide-y sticky left-0 bg-white min-w-sm md:w-1/5 flex-none text-right shadow-xl text-xs md:text-base"
            >
              <div
                class="h-32 flex justify-end pl-2 pr-4 md:pl-0 md:pr-6 items-center"
              ></div>
              <div
                class="h-16 flex justify-end pl-2 pr-4 md:pl-0 md:pr-6 items-center"
              >
                Wet Food
              </div>
              <div
                class="h-16 flex justify-end pl-2 pr-4 md:pl-0 md:pr-6 items-center"
              >
                Dry Food
              </div>
              <div
                class="h-16 flex justify-end pl-2 pr-4 md:pl-0 md:pr-6 items-center"
              >
                Food Type
              </div>
              <div
                class="h-16 flex justify-end pl-2 pr-4 md:pl-0 md:pr-6 items-center"
              >
                <div class="text-right">
                  High Meat Content
                  <div class="text-xs text-gray-500">(Min. 40%)</div>
                </div>
              </div>
              <div
                class="h-16 flex justify-end pl-2 pr-4 md:pl-0 md:pr-6 items-center"
              >
                No Added Sugar
              </div>
              <div
                class="h-16 flex justify-end pl-2 pr-4 md:pl-0 md:pr-6 items-center"
              >
                Grain Free
              </div>
              <div
                class="h-16 flex justify-end pl-2 pr-4 md:pl-0 md:pr-6 items-center"
              >
                Recyclable
              </div>
              <div
                class="h-16 flex justify-end pl-2 pr-4 md:pl-0 md:pr-6 items-center"
              >
                Price
              </div>
              <div
                class="h-24 flex justify-end pl-2 pr-4 md:pl-0 md:pr-6 items-center"
              >
                Rating
              </div>
              <div
                class="h-24 flex justify-end pl-2 pr-4 md:pl-0 md:pr-6 items-center"
              >
                Ownership
              </div>
              <div
                class="h-36 md:h-64 flex justify-end pl-2 pr-4 md:pl-0 md:pr-6 items-center"
              >
                Comments
              </div>

              <div class="h-24 flex"></div>
            </div>
            <div v-for="sub in subscriptions" class="divide-y min-w md:w-1/5">
              <div class="h-32 flex justify-center items-center">
                <div :class="sub.logoWidth">
                  <img :src="`/images/${sub.logo}`" />
                </div>
              </div>
              <div
                class="h-16 font-sans flex justify-center items-center text-lg md:text-3xl"
                :class="sub.items.wetFood ? 'text-green-500' : 'text-red-500'"
              >
                {{ sub.items.wetFood ? "&check;" : "&#10005;" }}
              </div>
              <div
                class="h-16 font-sans flex justify-center items-center text-lg md:text-3xl"
                :class="sub.items.dryFood ? 'text-green-500' : 'text-red-500'"
              >
                {{ sub.items.dryFood ? "&check;" : "&#10005;" }}
              </div>
              <div class="h-16 font-sans flex justify-center items-center">
                {{ sub.items.foodType }}
              </div>
              <div
                class="h-16 font-sans flex justify-center items-center text-lg md:text-3xl"
                :class="
                  sub.items.highMeatContent ? 'text-green-500' : 'text-red-500'
                "
              >
                {{ sub.items.highMeatContent ? "&check;" : "&#10005;" }}
              </div>
              <div
                class="h-16 font-sans flex justify-center items-center text-lg md:text-3xl"
                :class="
                  sub.items.noAddedSugar ? 'text-green-500' : 'text-red-500'
                "
              >
                {{ sub.items.noAddedSugar ? "&check;" : "&#10005;" }}
              </div>
              <div
                class="h-16 font-sans flex justify-center items-center text-lg md:text-3xl"
                :class="sub.items.grainFree ? 'text-green-500' : 'text-red-500'"
              >
                {{ sub.items.grainFree ? "&check;" : "&#10005;" }}
              </div>
              <div
                class="h-16 font-sans flex justify-center items-center text-lg md:text-3xl"
                :class="
                  sub.items.recyclable ? 'text-green-500' : 'text-red-500'
                "
              >
                {{ sub.items.recyclable ? "&check;" : "&#10005;" }}
              </div>
              <div
                class="h-16 flex justify-center items-center text-2xl md:text-4xl font-semibold text-gray-700"
              >
                {{ sub.items.price }}
              </div>
              <div
                class="h-24 flex flex-col space-y-2 justify-center items-center text-gray-700"
              >
                <div class="text-sm">{{ sub.items.reviews }}</div>
                <div class="w-24">
                  <img :src="`/images/${sub.items.stars}.svg`" />
                </div>
              </div>
              <div
                class="h-24 flex justify-center text-center p-4 text-xs md:text-base items-center break-normal whitespace-normal"
              >
                {{ sub.items.ownership }}
              </div>
              <div
                class="h-36 md:h-64 p-4 md:p-8 text-xs md:text-base text-center flex justify-center items-center break-normal whitespace-normal"
              >
                {{ sub.items.comments }}
              </div>
              <div
                class="h-24 text-sm md:text-base flex justify-center items-center"
                @click="sendEvent(sub)"
              >
                <a
                  :href="sub.url"
                  class="bg-yellow-300 hover:bg-yellow-400 text-gray-800 p-3 py-2 md:p-4 md:px-6 rounded-full cursor-pointer"
                >
                  View website &rarr;
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="text-center text-2xl pt-5 md:pt-8 font-semibold">
          <div class="text-5xl p-4 pl-0">😺</div>
          Meowwww... for now!
        </div>
      </div>
      <div class="text-xs w-full text-center text-gray-600 mx-auto py-4 pb-0">
        Last updated {{ monthDate }}
      </div>
      <div class="text-xs w-full text-center text-gray-600 mx-auto py-4">
        Republic of Cats logo is property of republicofcats.com. The Cool Cat
        Club logo is property of thecoolcatclub.com. Katkin logo is property of
        katkin.club. Blink logo is property of blinkcats.co.uk. Untamed logo is
        property of untamedcatfood.com.
      </div>
    </div>
  </div>
</template>

<script>
  import { format } from "date-fns";
  import mixpanel from "mixpanel-browser";
  export default {
    name: "Home",
    components: {},
    computed: {
      monthDate() {
        return format(new Date(), "MMMM, yyyy");
      },
    },
    data() {
      return {
        subscriptions: [
          {
            name: "Republic of Cats",
            logo: "roc-logo.svg",
            logoWidth: "w-logo md:w-40",
            url: "https://bit.ly/3puHPo4",
            items: {
              dryFood: true,
              wetFood: true,
              foodType: "Adult",
              highMeatContent: false,
              noAddedSugar: false,
              grainFree: false,
              recyclable: true,
              price: "££",
              stars: "4-5",
              reviews: "4.6/5 on Trustpilot",
              ownership: "Part of Nestle Purina/Tails",
              comments:
                "Good price although food quality is poor. Nowhere near as healthy as others.",
            },
          },
          {
            name: "The Cool Cat Club",
            logo: "ccc-logo.png",
            logoWidth: "w-20 md:w-24",
            url: "https://bit.ly/3lFN4Qs",
            items: {
              dryFood: true,
              wetFood: true,
              foodType: "Adult / Kitten",
              highMeatContent: true,
              noAddedSugar: true,
              grainFree: true,
              recyclable: true,
              price: "££",
              reviews: "4.9/5 on Trustpilot",
              stars: "5",
              ownership: "Independent",
              comments:
                "Good food quality at a good price. Quick responses from customer service. Not all options recyclable. Give pouches to cat shelters. ",
            },
          },
          {
            name: "Katkin",
            logo: "katkin-logo.png",
            logoWidth: "w-20 md:w-24",
            url: "https://bit.ly/3lIO0nd",
            items: {
              dryFood: false,
              wetFood: true,
              foodType: "Adult",
              highMeatContent: true,
              noAddedSugar: true,
              grainFree: true,
              recyclable: false,
              price: "££££",
              reviews: "4.5/5 on Trustpilot",
              stars: "4-5",
              ownership: "Multiple owners incl. Venture Capitalists",
              comments:
                "Good food, considerably more expensive than others. Requires freezer space. Recyclability appears poor",
            },
          },
          {
            name: "Blink",
            logo: "blink-logo.png",
            logoWidth: "w-20 md:w-24",
            url: "https://bit.ly/3otA8im",
            items: {
              dryFood: false,
              wetFood: true,
              foodType: "Adult / Kitten",
              highMeatContent: true,
              noAddedSugar: true,
              grainFree: true,
              recyclable: false,
              price: "£££",
              reviews: "4.9/5 on Trustpilot",
              stars: "5",
              ownership: "Part of Butchers Pet Care",
              comments:
                "Good food quality, can be found in supermarkets. Non recyclable packaging.",
            },
          },
          {
            name: "Untamed",
            logo: "untamed-logo.png",
            logoWidth: "w-20 md:w-24",
            url: "https://bit.ly/3orNjAC",
            items: {
              dryFood: false,
              wetFood: true,
              foodType: "Adult",
              highMeatContent: true,
              noAddedSugar: true,
              grainFree: true,
              recyclable: true,
              price: "£££",
              reviews: "4.7/5 on Trustpilot",
              stars: "5",
              ownership: "Unknown",
              comments:
                "Good food quality, although pricey. Not all meals are Complete Food.",
            },
          },
        ],
      };
    },
    methods: {
      sendEvent(sub) {
        mixpanel.track("Clicked Link", {
          name: sub.name,
          url: sub.url,
        });
      },
    },
    mounted() {
      mixpanel.track("Page Loaded");
    },
  };
</script>
<style lang="css">
  .min-w {
    min-width: 150px;
  }
  .min-w-sm {
    min-width: 110px;
  }
  .w-logo {
    width: 120px;
  }
</style>
